<template>
  <div class="errPage-container">
    <el-button v-show="showBack" icon="arrow-left" class="pan-back-btn" @click="back">
      {{ $t('errorPages.return') }}
    </el-button>
    <el-button v-show="showHome" icon="arrow-left" class="pan-back-btn" @click="home">
      {{ $t('errorPages.backToHome') }}
    </el-button>
    <el-row>
      <el-col :span="12">
        <h1 class="text-jumbo text-ginormous">{{ title }}<span>.</span></h1>
        <h2>{{ headline }}</h2>
        <h4>{{ message }}</h4>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
    message: String,
    showBack: {
      default: true,
      type: Boolean
    },
    showHome: {
      default: true,
      type: Boolean
    },
    title: String
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.go(-1);
      }
    },
    home() {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.errPage-container {
  width: 50%;
  max-width: 100%;
  margin: 100px auto;

  .pan-back-btn {
    background: #e4061f;
    color: #fff;
    border: none !important;
  }

  .pan-gif {
    margin: 0 auto;
    display: block;
  }

  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    font-family: HelveticaNeueLTStd-Blk, open Sans-Serif, Arial;
    line-height: 55px;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-shadow: 0 1px 1px #343434;
    span {
      color: #e4061f;
      font-family: Times New Roman;
      font-size: 71px;
    }
  }

  .list-unstyled {
    font-size: 14px;

    li {
      padding-bottom: 5px;
    }
  }
  .el-button {
    margin-right: 5px;
  }
  .el-button + .el-button {
    margin-top: 10px;
    margin-left: 0px;
  }
}
</style>
