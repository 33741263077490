<template>
  <div class="401-container">
    <errorPage
      :title="$t('errorPages.title404')"
      :headline="$t('errorPages.headline404')"
      :message="$t('errorPages.message404')"
    />
  </div>
</template>

<script>
import errorPage from './errorPage.vue';
export default {
  name: 'Page404',
  components: {
    errorPage
  },
  data() {
    return {
      message: 'Page not found'
    };
  }
};
</script>
